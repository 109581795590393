import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);
gsap.defaults({ ease: "none", duration: 2});

function setScrollTrigger(trigger) {
  return {
    trigger: trigger,
    start: "top 80%",
    end: "bottom 20%",
    scrub: true,
  }
}

function initParallax() {
  gsap.utils.toArray('.from-y-minus-30').forEach(target => {
    gsap.from(target, {
      scrollTrigger: setScrollTrigger(target),
      y: "-20%",
    });
  });

  gsap.utils.toArray('.from-y-plus-30').forEach(target => {
    gsap.from(target, {
      scrollTrigger: setScrollTrigger(target),
      y: "20%",
    });
  });

  // gsap.utils.toArray('.to-y-minus-30').forEach(target => {
  //   gsap.to(target, {
  //     scrollTrigger: setScrollTrigger(target),
  //     y: "-30%",
  //   });
  // });
  //
  // gsap.utils.toArray('.to-y-plus-30').forEach(target => {
  //   gsap.to(target, {
  //     scrollTrigger: setScrollTrigger(target),
  //     y: "30%",
  //   });
  // });
}

if (document.readyState === 'complete') {
  // safari
  initParallax();
} else {
  window.addEventListener("load", initParallax);
}
