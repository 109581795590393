import jumpTo from 'jump.js'

//Get the button:
const buttonScrollTop = document.getElementById("scrollTopBtn");
const buttonScrollMain = document.getElementById("scrollToMain");

// When the user clicks on the button, scroll to the top of the document
function jumpToTop() {
  jumpTo(document.body, { duration: 1000, offset: 0, })
}

function jumpToMain() {
  const mainElement = document.getElementById("main");
  if (mainElement) {
    jumpTo(document.getElementById("main"), { duration: 1000, offset: 0, })
  }
}

if (buttonScrollTop) {
  buttonScrollTop.addEventListener("click", jumpToTop, {passive: true})
}

if (buttonScrollMain) {
  buttonScrollMain.addEventListener("click", jumpToMain, {passive: true})
}
