// Swiper JS
import Swiper, {Navigation, Autoplay} from "swiper"

function initSwiper(event) {
    const backendSettings = {
        autoplay: false,
        grabCursor: false,
        draggable: false,
        allowTouchMove: false,
    }

    new Swiper('.swiper-images', {
        modules: [Navigation, Autoplay],
        slidesPerView: "auto",
        spaceBetween: 16,
        grabCursor: true,
        loop: true,
        observer: true,
        observeSlideChildren: true,
        resizeObserver: true,
        autoplay: {
            delay: 2000,
        },
        breakpoints: {
            992: {
                slidesPerView: 6,
            },
            1200: {
                slidesPerView: 9,
            },
        }
    });
    new Swiper('.swiper-images-backend', {
        ...backendSettings,
        modules: [Navigation, Autoplay],
        slidesPerView: 1,
        spaceBetween: 16,
        loop: true,
        resizeObserver: true,
        observer: true,
        observeSlideChildren: true,
        pagination: {
            el: '.pagination-images',
            clickable: true,
        },
    });
}

window.addEventListener("load", initSwiper)
